<template>
  <main>
    <div class="row mt-5 m-0">
      <div class="col-xl-10 m-auto">
        <div class="row mx-0 mb-4">
          <h2 class="col-xl-10">Paiements</h2>
          <button class="col-xl-2 btn btn-outline-dark float-right" @click="imprimer"><i class="fa fa-print me-2"></i>Imprimer</button>
        </div>
        <div class="row mx-0">
          <div class="col-xl-3 mb-4">
            <b-form-select class="form-select mb-3" v-model="filtre_name" >
              <b-form-select-option  value="">Clients</b-form-select-option>
              <b-form-select-option v-for="c in clients" :key="c.fullname" :value="c.fullname">{{c.fullname}}</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-xl-3 mb-4">
            <b-input-group >
              <template #prepend>
                <b-input-group-text>De</b-input-group-text>
              </template>
              <b-form-input type="date" v-model="filtre_from" ></b-form-input>
            </b-input-group>
          </div>
          <div class="col-xl-3 mb-4">
            <b-input-group >
              <template #prepend>
                <b-input-group-text>A</b-input-group-text>
              </template>
              <b-form-input type="date" v-model="filtre_to" ></b-form-input>
            </b-input-group>
          </div>
        </div>
        <div class="mb-4" style="border-radius:12px;border: solid 3px #32394e">
          <div   id="section-to-print" class=" m-auto">
            <div class="row header mb-4">
              <h1 class="col-xl-9 m-auto text-center">
                VR ESCAPE ROOM
              </h1>

            </div>
          <table class="tab text-center">
            <thead>
            <tr>
              <th scope="col" class="owner">CLient</th>
              <th scope="col">Date de paiement</th>
              <th scope="col">Montant</th>

            </tr>
            </thead>
            <tbody>
            <tr v-for="data in payments" :key="data.id">
              <td data-label="Client" class="owner">{{data.client.fullname}}</td>
              <td data-label="Date de Paiement"> {{new Date(data.paid_at).toLocaleString()}}</td>
              <td data-label="Montant">{{data.amount}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        </div>
        <infinite-loading @infinite="infiniteHandler" class="text-center">
          <div slot="no-more">Aucune autre donnée trouvée</div>
          <div slot="no-results">Aucune donnée trouvée</div>
        </infinite-loading>



      </div>
    </div>
  </main>
</template>

<script>



export default {
  name: "Payments",

  data(){
    return{
      payments:[],
      clients:[],
      page:1,
      print:false,
      filtre_name:'',
      filtre_from:'',
      filtre_to:'',

    }
  },

  watch:{
    filtre_name: function(){this.filterSearch()},
    filtre_from: function(){this.filterSearch()},
    filtre_to: function(){this.filterSearch()}
  },
  created() {
    this.getClients()
  },
  methods:{
    infiniteHandler($state) {
      this.$http.get('payments?page=' +this.page).then(({ data }) => {
        if (data.data.length) {
          this.page += 1;
          this.payments.push(...data.data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    imprimer() {
      window.print();
    },
    filterSearch() {
      this.$http
          .post('search/payments',
              {'fullname':this.filtre_name,'from':this.filtre_from,'to':this.filtre_to},

          )
          .then(response => {
            console.log(response.data)
            this.payments = response.data.data;
          })
          .catch(error => {
            console.log(error)
          })
    },
    getClients(){
      this.$http
          .get('clients')
          .then(response => {
            this.clients = response.data
          })
          .catch(error => {
            console.log(error)
          })
    }

  }
}

</script>

<style scoped  src="@/assets/table.css"></style>
<style src="@/assets/imprimer.css"></style>
<!--<style scoped>-->
<!--table ,td,tr,th{-->
<!--  border-top: 1px solid black !important;-->
<!--  border-collapse: collapse;-->
<!--}-->
<!--table{-->
<!--  width: 97%;-->
<!--}-->




<!--</style>-->